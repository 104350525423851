import { gql } from "graphql-tag";
import {
  coupon,
  coupons,
  couponsVariables,
  couponVariables,
  createCoupon,
  createCouponVariables,
  updateCoupon,
  updateCouponVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const CouponPayload = gql`
  fragment CouponPayload on Coupon {
    id
    active
    amount
    amountType
    applyAtCheckout
    applyOnce
    availableForAllOfferings
    code
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    department {
      id
      allowMultipleCoupons
      title
    }
    enabledByDefault
    expirationDate
    frequency
    maxAccountRedemptions
    maxRedemptions
    startDate
    title
  }
`;

export const UpdateCouponMutation = gql`
  mutation updateCoupon($data: CouponCreateInput!, $where: UniqueInput!) {
    updateCoupon(data: $data, where: $where) {
      ...CouponPayload
    }
  }
  ${CouponPayload}
` as TypedDocumentNode<updateCoupon, updateCouponVariables>;

export const DeleteCouponMutation = gql`
  mutation deleteCoupon($id: String!) {
    deleteCoupon(where: { id: $id }) {
      id
    }
  }
`;

export const CreateCouponMutation = gql`
  mutation createCoupon($data: CouponCreateInput!) {
    createCoupon(data: $data) {
      ...CouponPayload
    }
  }
  ${CouponPayload}
` as TypedDocumentNode<createCoupon, createCouponVariables>;

export const CouponsQuery = gql`
  query coupons(
    $organizationId: String!
    $departmentId: String
    $filter: String
  ) {
    coupons(
      where: { organizationId: $organizationId, departmentId: $departmentId }
      filter: $filter
    ) {
      ...CouponPayload
      counts {
        offerings
        usage
        total
      }
    }
  }
  ${CouponPayload}
` as TypedDocumentNode<coupons, couponsVariables>;

export const CouponQuery = gql`
  query coupon($id: String!) {
    coupon(where: { id: $id }) {
      ...CouponPayload
      counts {
        offerings
        usage
        total
      }
    }
  }
  ${CouponPayload}
` as TypedDocumentNode<coupon, couponVariables>;
